import React , {useState} from 'react';
import axios from 'axios';
import './App.css';


const APP_IMAGE_PARH = '';

function App() {

  const [user, setUser] = useState({
    formSent:false,
    fullName: "", 
    phone: "", 
    email: "",
    subject: '' 
  });

  
  const { fullName, phone, email,subject } = user;



  const handleSubmit = async (e) => {
    e.preventDefault();
    if(fullName === "") {
      alert("אנא הכנס שם פרטי")
      return;
    }
    if(phone === "") {
      alert("אנא הכנס טלפון")
      return;
    }
     if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      alert("אנא הכנס מייל תקין")
      return;
    }


    // const res = await axios.post('http://itay.gershona.com/api.php' , {
    //   sendData:true,
    //   data:{ 
    //     ...user
    //   }
    // });

    // const {data} = res;

    // if ( data.response === false ) {
    //   alert(data.msg);
    // }else {

    // }

      setUser({...user,formSent:true})

  }


  return (
    <>
      <section className="app-section app-section-nav">
        <div className="app-container">
          <div className="app-row app-nav-row app-flexalign-center ">
            <div className="app-col app-logo-wrapper">
              <a href="./">
                MyDigital
              </a>
            </div>

            <div className="app-col app-col-info app-flex app-flexalign-center">
              
              <div className="app-icon-text-box">
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-user fa-w-14 fa-2x"><path fill="currentColor" d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" class=""></path></svg>
                <span>
                  מוניקה 
                </span>
              </div>

              <div className="app-icon-text-box">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-phone fa-w-16 fa-2x"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z" class=""></path></svg>
                <span>
                  <a href="tel:0505686357">
                    0505686357
                  </a> 
                </span>
              </div>

              <div className="app-icon-text-box">
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-envelope fa-w-16 fa-2x"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z" class=""></path></svg>
                <span>
                  <a href="mailto:‏‪monika.larson@rambler.ru‬‏">
                  ‏‪monika.larson@rambler.ru‬‏
                  </a> 
                </span>
              </div>
                
            </div>

            <div className="app-col app-logo-2-wrapper">
              <img src="https://itay.gershona.com/bg.jpg" className="app-top-image" />
            </div>
          </div>
        </div>
      </section>

      <section className="app-section app-section-1">
        <div className="app-container">
          <div className="app-row">
            <div className="app-col">
                <h1>  
                 בניית אתרי אינטרנט<br/>
                </h1>
                <h2>
                  מקצועיות איכות ומחירים מעולים<br/>
                  בשביל המשרד שלך באינטרנט.
                </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="app-section app-section-2">
        <div className="app-container">
          <div className="app-row app-flexalign-start app-justify-spacebetween">
            <div className="app-col app-col-1">
                <div className="app-text-wrapper">
                  <p>
                    בניית אתרים לחברות, אנחנו נהפוך את העסק שלך לעסק אינטרנטי שמתקשר עם כל העולם,
                  </p>
                  <p>
                    הפוך את העסק שלך ליוקרתי, מזמין ונגיש בקליק לכל<br/> אחד ואחת.
                  </p>
                  <p>
                    אתרי תדמית, חנויות וירטואליות, דפי נחיתה, כרטיסי ביקור וירטואלי, קידום אתרים, ועוד..
                  </p>
                </div>
            </div>

            <div className="app-col app-form-col app-col-2">
              <div className="app-form-wrapper">
                <form onSubmit={handleSubmit}>
                  <div className="app-text-align-center app-title-wrapper">
                    <h3>
                      השאר  פרטים<br/>
                      עכשיו ונחזור אליך
                    </h3>
                  </div>


                  <div className="app-input-wrapper">
                    <input placeholder="שם*" value={fullName} name="fullName" onChange={(e) => setUser({...user,[e.target.name]: e.target.value })}/>
                  </div>
                  <div className="app-input-wrapper">
                     <input placeholder="טלפון (נייד)*" value={phone} name="phone" onChange={(e) => setUser({...user,[e.target.name]: e.target.value })}/>
                  </div>
                  <div className="app-input-wrapper">
                    <input placeholder="אימייל*" value={email} name="email" onChange={(e) => setUser({...user,[e.target.name]: e.target.value })}/>
                  </div>

                  <div className="app-input-wrapper">
                    <textarea placeholder="הודעה*" name={'subject'} value={subject}  onChange={(e) => setUser({...user,[e.target.name]: e.target.value })}> </textarea>
                  
                  </div>


                  <div className="app-input-wrapper app-submit-wrapper">
                   
                    {
                      user.formSent === false
                      ?  <button type="submit">שליחה</button>
                      : <h3>תודה שהשארתם פרטים</h3>
                    }
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;

